<template>
	<v-container>
		<v-card>
			<v-card-title>Verfügbarkeit</v-card-title>
			<v-card-subtitle> Ist ein Tag rot markiert, dann ist die Wohnung an diesem Tag nicht verfügbar. </v-card-subtitle>
			<v-card-text>
				<v-toolbar flat>
					<v-btn icon @click="value = ''"><v-icon>mdi-calendar-today</v-icon></v-btn>
					<v-spacer></v-spacer>
					<v-btn icon class="ma-2" @click="$refs.calendar.prev()">
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-toolbar-title v-if="$refs.calendar">
						{{ $refs.calendar.title }}
					</v-toolbar-title>
					<v-btn icon class="ma-2" @click="$refs.calendar.next()">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
				</v-toolbar>
				<v-calendar
					ref="calendar"
					v-model="value"
					:events="events"
					:weekdays="weekday"
					type="month"
					locale="de"
					color="primary"
					:show-month-on-first="false"
				></v-calendar>
				<v-card-actions> </v-card-actions>
			</v-card-text>
		</v-card>
		<br />
		<v-card>
			<v-card-title>Mietvertrag, Preise &amp; Reiserücktritt</v-card-title>
			<v-card-subtitle
				>Eine verbindliche Buchung erfolgt mit Zusendung des unterschriebenen Mietvertrages. Der unausgefüllte
				Mietvertrag kann
				<a href="https://fewokruse.de/downloads/Mietvertrag-FewoKruse.pdf"
					>hier heruntergeladen werden.</a
				></v-card-subtitle
			>
			<v-card-subtitle
				>Als Gäste sind max. 7 Personen möglich. (5 Einzelbetten, 1 Schlafsofa für 2 Personen). Im Grundpreis sind
				<strong>zwei Personen inklusive</strong>, für jede weitere Person wird ein Aufpreis fällig.
			</v-card-subtitle>
			<v-card-text>
				<v-simple-table>
					<tbody>
						<tr>
							<td>Grundpreis 1. März bis 31. Mai:</td>
							<td>64,50 EUR pro Nacht</td>
						</tr>
						<tr>
							<td>Grundpreis 1. Juni bis 30. September:</td>
							<td>79,50 EUR pro Nacht</td>
						</tr>
						<tr>
							<td>Grundpreis 1. Oktober bis 28. Februar:</td>
							<td>49,50 EUR pro Nacht</td>
						</tr>
						<tr>
							<td>Aufpreis pro weitere Person:</td>
							<td>+8,50 EUR pro Nacht</td>
						</tr>
						<tr>
							<td>Pauschale für Endreinigung:</td>
							<td>95,00 EUR einmalig</td>
						</tr>
						<tr>
							<td>Bettwäsche + Handtücher:</td>
							<td>12,50 EUR pro Person</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
			<v-card-subtitle>Reiserücktritt:</v-card-subtitle>
			<v-card-text>
				<p>
					Auch der perfekt geplante Urlaub kann aus Gründen ins Wasser fallen. Wir können folgende Rücktrittskonditionen
					anbieten (es gilt der Zeitpunkt des Rücktritts):
				</p>
				<v-simple-table>
					<tbody>
						<tr>
							<td>Bis 30 Tage vor Urlaubsbeginn:</td>
							<td>100% Erstattung / Erlass des Mietpreises</td>
						</tr>
						<tr>
							<td>Bis 14 Tage vor Urlaubsbeginn:</td>
							<td>50% Erstattung / Erlass des Mietpreises</td>
						</tr>
						<tr>
							<td>Ab 14 Tage vor Urlaubsbeginn:</td>
							<td>0% Erstattung / Erlass des Mietpreises</td>
						</tr>
					</tbody>
				</v-simple-table>
				<br />
				<p>Wir empfehlen den Abschluss einer Reiserücktrittsversicherung.</p>
			</v-card-text>
		</v-card>
		<br />
	</v-container>
</template>

<script>
import ical from 'cal-parser';

export default {
	name: 'ScheduleCard',
	data: () => ({
		events: [],
		value: '',
		weekday: [1, 2, 3, 4, 5, 6, 0],
	}),
	async mounted() {
		this.$refs.calendar.next(0); // updates title

		// get calendar data and transform it into events
		await fetch('https://fewokruse.de/php/ical.php').then((response) => {
			return response.text().then((text) => {
				let parsedCal = ical.parseString(text);
				for (const el of parsedCal.events) {
					// end has to be shortened by one second to end on correct day
					let end = new Date(el.dtend.value);
					end = end.setTime(end.getTime() - 1000);

					this.events.push({
						name: '',
						start: el.dtstart.value,
						end: end,
						color: 'red',
					});
				}
			});
		});
	},
};
</script>

<style></style>
