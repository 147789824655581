<template>
	<v-container>
		<h4 class="mb-4">Bülk</h4>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'BuelkBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			{
				title: 'Leuchtturm Pavillion',
				subtitle: 'Kleines Restaurant / Bistro',
				img: 'https://fewokruse.de/img/buelk.jpg',
				url: 'http://www.leuchtturm-buelk.de/index.html',
				maps: 'https://goo.gl/maps/srvty9hWMp6EVnzi8',
				descr:
					'Ideal z.B. als Ziel für einen ausgedehnten Spaziergang von Strande aus an der Küste entlang bis zum Leuchtturm Bülk. Neben dem tollen Meerblick dort gibt es leckeres zu Essen und zu Trinken. Der Leuchtturm kann außerhalb von Pandemien oder Renovierungen besichtigt werden, bitte vorher auf der Website schauen, ob er geöffnet ist.',
			},
			{
				title: 'Spaziergang zum Leuchtturm',
				subtitle: 'An der Küste entlang zum Leuchtturm laufen',
				img: 'https://fewokruse.de/img/strandebuelk.jpg',
				url: '',
				maps: 'https://goo.gl/maps/R232Upu4rJU8gcX67',
				descr:
					'Einfach das Auto auf dem Parkplatz am Ortsausgang von Strande abstellen, loslaufen und Luft & Aussicht genießen. Am Leuchtturm angekommen, kann man sich zur Belohnung einen Kaffee gönnen.',
			},
		],
	}),
};
</script>

<style></style>
