<template>
	<div>
		<v-app-bar app dense clipped-left>
			<v-app-bar-nav-icon x-large float @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title class="ml-0 pl-4">Ferienwohnung Kruse</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click="$router.push(`/kontakt`)"><v-icon>mdi-email</v-icon></v-btn>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app clipped src="https://fewokruse.de/img/backgroundmenu.jpg">
			<v-list-item to="/">
				<v-list-item-content>
					<v-list-item-title class="text-h6">fewokruse.de</v-list-item-title>
					<v-list-item-subtitle>Startseite</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list nav shaped>
				<v-list-group v-for="item in items" :key="item.title" :prepend-icon="item.icon" no-action>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</template>
					<v-list-item v-for="subItem in item.items" :key="subItem.title" :to="subItem.to">
						<v-list-item-content>
							<v-list-item-title>{{ subItem.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<v-list-item to="/verfuegbarkeit">
					<v-list-item-icon>
						<v-icon>mdi-calendar-cursor</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Verfügbarkeit &amp; Preise</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item to="/kontakt">
					<v-list-item-icon>
						<v-icon>mdi-email</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Anfragen &amp; Buchen</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	name: 'NavigationComponent',
	data: () => ({
		drawer: null,
		items: [
			{
				icon: 'mdi-home',
				title: 'Wohnung',
				items: [
					{ title: 'Wohnzimmer', to: '/wohnung/wohnzimmer' },
					{ title: 'Küche', to: '/wohnung/kueche' },
					{ title: 'Bad (EG)', to: '/wohnung/badeg' },
					{ title: 'Schlafen 1 (OG)', to: '/wohnung/schlafen1og' },
					{ title: 'Schlafen 2 (OG)', to: '/wohnung/schlafen2og' },
					{ title: 'Bad (OG)', to: '/wohnung/badog' },
				],
			},
			{
				icon: 'mdi-ferry',
				title: 'Umgebung',
				items: [
					{ title: 'Friedrichsort', to: '/umgebung/friedrichsort' },
					{ title: 'Falckenstein', to: '/umgebung/falckenstein' },
					{ title: 'Schilksee', to: '/umgebung/schilksee' },
					{ title: 'Strande', to: '/umgebung/strande' },
					{ title: 'Bülk', to: '/umgebung/buelk' },
					{ title: 'Weitere Ziele', to: '/umgebung/weitereziele' },
				],
			},
		],
	}),
};
</script>

<style></style>
