<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="6" offset-lg="3">
				<v-carousel height="100%" cycle show-arrows-on-hover>
					<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src"></v-carousel-item>
				</v-carousel>
			</v-col>

			<v-col>
				<h4>Schlafzimmer 1 (Obergeschoss).</h4>
				<ul>
					<li>Drei Betten mit jew. Nachttisch &amp; Leselicht</li>
					<li>Jedes Bett mit elektr. verstellbarem Lattenrost</li>
					<li>Ein großer Kleiderschrank</li>
					<li>Ankleidespiegel</li>
					<li>Die Betten werden vor Anreise frisch bezogen</li>
				</ul>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Schlafen1OG',
	data: () => ({
		items: [
			{
				src: 'https://fewokruse.de/img/schlafen1og001.jpg',
			},
			{
				src: 'https://fewokruse.de/img/schlafen1og002.jpg',
			},
			{
				src: 'https://fewokruse.de/img/schlafen1og003.jpg',
			},
		],
	}),
};
</script>

<style></style>
