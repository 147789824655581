import Vue from 'vue';
import VueRouter from 'vue-router';
import BuelkBase from '../components/umgebung/BuelkBase.vue';
import ContactForm from '../components/ContactForm';
import FalckensteinBase from '../components/umgebung/FalckensteinBase.vue';
import FriedrichsortBase from '../components/umgebung/FriedrichsortBase.vue';
import HomeComponent from '../components/HomeComponent';
import ScheduleCard from '../components/ScheduleCard';
import SchilkseeBase from '../components/umgebung/SchilkseeBase';
import Schlafen1OG from '../components/wohnung/Schlafen1OG';
import Schlafen2OG from '../components/wohnung/Schlafen2OG';
import StrandeBase from '../components/umgebung/StrandeBase.vue';
import BadEG from '../components/wohnung/BadEG';
import BadOG from '../components/wohnung/BadOG';
import KuecheEG from '../components/wohnung/KuecheEG';
import WeitereZieleBase from '../components/umgebung/WeitereZieleBase';
import WohnzimmerEG from '../components/wohnung/WohnzimmerEG';

Vue.use(VueRouter);

const routes = [
	{ path: '*', component: HomeComponent }, // not found
	{ path: '/', component: HomeComponent },
	{ path: '/kontakt', component: ContactForm },
	{ path: '/verfuegbarkeit', component: ScheduleCard },
	{ path: '/umgebung/buelk', component: BuelkBase },
	{ path: '/umgebung/falckenstein', component: FalckensteinBase },
	{ path: '/umgebung/friedrichsort', component: FriedrichsortBase },
	{ path: '/umgebung/schilksee', component: SchilkseeBase },
	{ path: '/umgebung/weitereziele', component: WeitereZieleBase },
	{ path: '/wohnung/schlafen1og', component: Schlafen1OG },
	{ path: '/wohnung/schlafen2og', component: Schlafen2OG },
	{ path: '/umgebung/strande', component: StrandeBase },
	{ path: '/wohnung/badeg', component: BadEG },
	{ path: '/wohnung/badog', component: BadOG },
	{ path: '/wohnung/kueche', component: KuecheEG },
	{ path: '/wohnung/wohnzimmer', component: WohnzimmerEG },
];

/**
 * With "mode: 'history'" the server (apache2) needs to
 * always return "index.html"!
 */
const router = new VueRouter({
	routes,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

export default router;
