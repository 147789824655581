<template>
	<v-app>
		<NavigationComponent />
		<v-main><router-view></router-view></v-main>
		<FooterComponent />
	</v-app>
</template>

<script>
import NavigationComponent from './components/NavigationComponent';
import FooterComponent from './components/FooterComponent';

export default {
	name: 'App',

	components: {
		NavigationComponent,
		FooterComponent,
	},

	data: () => ({
		//
	}),
};
</script>

<style>
/* Don't show unnecessary scroolbar */
html {
	overflow-y: auto !important;
}
</style>
