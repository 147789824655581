<template>
	<v-card>
		<v-img height="250" :src="l.img"></v-img>
		<v-card-title>{{ l.title }}</v-card-title>
		<v-card-subtitle>{{ l.subtitle }}</v-card-subtitle>
		<v-card-text>{{ l.descr }}</v-card-text>
		<v-card-actions>
			<v-btn text :href="l.url" target="_blank" :disabled="!l.url"><v-icon left>mdi-web</v-icon> Website </v-btn>
			<v-spacer></v-spacer>
			<v-btn text :href="l.maps" target="_blank" :disabled="!l.maps"><v-icon left>mdi-map</v-icon> Maps </v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'LocationCard',
	props: ['l'],
};
</script>

<style></style>
