<template>
	<v-container>
		<h4 class="mb-4">Falckenstein</h4>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'FalckensteinBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			{
				title: 'Falckensteiner Strand',
				subtitle: 'Gebührenfreier Sandstrand',
				img: 'https://fewokruse.de/img/falckensteinerstrand.jpg',
				url: 'https://www.kiel.de/de/kultur_freizeit/baeder_straende/strand_falckenstein.php',
				maps: 'https://goo.gl/maps/iwfVXsxfbe16xeRAA',
				descr: 'Weitläufiger Sandstrand, mit grandiosem Ausblick auf die vorbeifahrenden Schiffe.',
			},
			{
				title: 'High Spirits',
				subtitle: 'Hochseilgarten Kiel',
				img: 'https://fewokruse.de/img/highspirits.jpg',
				url: 'https://www.hochseilgarten-kiel.de',
				maps: 'https://g.page/hochseilgarten-kiel?share',
				descr: 'Klettern für Anfänger und Anspruchsvolle. Ein toller Spaß für Groß und Klein.',
			},
			{
				title: 'Deichperle',
				subtitle: 'Strandcafé beim Leuchtturm',
				img: 'https://fewokruse.de/img/deichperle.jpg',
				url: 'https://www.deichperle-kiel.de/',
				maps: 'https://goo.gl/maps/P1XhuKQ5mVwbtjp26',
				descr:
					'Schiffe aus nächster Nähe beobachten und lokale Leckereien genießen. Bequem mit dem Auto und dem Fahrrad erreichbar - einfach immer am Deich entlang.',
			},
			{
				title: 'Elefant am Strand',
				subtitle: 'Café direkt an der Küste',
				img: 'https://fewokruse.de/img/elefant.jpg',
				url: 'https://elefant-am-strand.de/',
				maps: 'https://goo.gl/maps/Sizfua9z69K2VZxw9',
				descr: 'Hier gibt es leckeren Kuchen und leckere Waffeln!',
			},
			{
				title: 'Steilküste',
				subtitle: 'Fußweg entlang der Steilküste',
				img: 'https://fewokruse.de/img/steilkueste.jpg',
				url: '',
				maps: 'https://goo.gl/maps/Z6mLNrZWDA9egBuL7',
				descr:
					'Auf der einen Seite viel Grün, auf der anderen viel Blau - ein schönes Plätzchen für einen entspannten Spaziergang z.B. bis zum "Elefant am Strand".',
			},
		],
	}),
};
</script>

<style></style>
