<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="6" offset-lg="3">
				<v-carousel height="100%" cycle show-arrows-on-hover>
					<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src"></v-carousel-item>
				</v-carousel>
			</v-col>

			<v-col><h4>Das Bad im Obergeschoss.</h4></v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'BadOG',
	data: () => ({
		items: [
			{
				src: 'https://fewokruse.de/img/badog01.jpg',
			},
			{
				src: 'https://fewokruse.de/img/badog02.jpg',
			},
			{
				src: 'https://fewokruse.de/img/badog03.jpg',
			},
		],
	}),
};
</script>

<style></style>
