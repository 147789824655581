<template>
	<v-container>
		<h4 class="mb-4">Weitere Ziele in der Umgebung</h4>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'WeitereZieleBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			{
				title: 'Marine-Ehrenmal & U 995',
				subtitle: 'Ehrenmal und Technisches Museum',
				img: 'https://fewokruse.de/img/uboot.jpg',
				url: 'https://deutscher-marinebund.de/marine-ehrenmal-u-995/preise-oeffnungszeiten/',
				maps: 'https://goo.gl/maps/sbkxoGRUx5Zva6nN6',
				descr:
					'Kriegsdenkmal mit Turm und Aussichtsplattform, erbaut 1936 zu Ehren der Marinesoldaten des Ersten Weltkriegs. Außerdem ein begehbares U-Boot aus dem 2. Weltkrieg. Für Buchheim- und/oder Technikfans ein Muss!',
			},
			{
				title: 'Raddampfer Freya',
				subtitle: 'Historischer Raddampfer für Ausflugsfahrten',
				img: 'https://fewokruse.de/img/raddampfer.jpg',
				url: 'https://www.adler-schiffe.de/schiffe/2-Raddampfer-Freya.html',
				maps: 'https://goo.gl/maps/2RdLeKRcwDTQvMnb9',
				descr:
					'Von der Website: "Lassen Sie sich einfangen von der originellen Atmosphäre an Bord des 115 Jahre alten stilvollen Raddampfers „Freya“." Eine Fahrt mit diesem Dampfer durch den Nord-Ostsee-Kanal muss man mal erlebt haben.',
			},
			{
				title: 'Das Hörnbad',
				subtitle: 'Modernes Hallenbad im Herzen Kiels',
				img: 'https://www.kiel.de/images/800x500freizeit_17401.jpg',
				url: 'https://www.kiel.de/de/kultur_freizeit/baeder_straende/hoernbad.php',
				maps: 'https://goo.gl/maps/HYm9scFxD64LmhkX9',
				descr:
					'Nicht nur an Regentagen ein tolles Badevergnügen. Der Sportbereich glänzt mit einem 1m, 3m und 5m hohen Sprungturm sowie 50m Bahnen (Nutzungszeiten auf Website beachten!). Desweiteren gibt es eine Rutsche mit Luftreifen.',
			},
		],
	}),
};
</script>

<style></style>
