<template>
	<div>
		<v-form v-model="valid" ref="contactform">
			<v-container>
				<v-card>
					<v-overlay absolute :value="overlay">
						<center>
							<v-alert dismissible prominent type="success" width="400" @input="overlay = false">
								Nachricht übermittelt
							</v-alert>
						</center>
					</v-overlay>
					<v-card-title>Kontakt</v-card-title>
					<v-card-subtitle
						>Sende hier deine Anfrage an uns. Bei Buchungsanfragen unbedingt vorher die
						<a href="/verfuegbarkeit">Verfügbarkeit prüfen!</a></v-card-subtitle
					>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6">
								<v-text-field v-model="name" :rules="nameRules" :counter="50" label="Dein Name" required></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field
									v-model="email"
									:rules="emailRules"
									:counter="50"
									label="Deine Emailadresse"
									required
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="message" :rules="messageRules" :counter="1000" label="Deine Nachricht:" required>
								</v-textarea>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text :disabled="!valid" @click="submit">Absenden<v-icon right>mdi-email-fast</v-icon></v-btn>
					</v-card-actions>
				</v-card>
				<br />
				<v-icon>mdi-email</v-icon> Oder du schickst uns eine
				<a href="mailto:info@fewokruse.de">Email an info@fewokruse.de</a>.
			</v-container>
		</v-form>
		<iframe style="display: none" name="hidden-form"></iframe>
	</div>
</template>

<script>
export default {
	name: 'ContactForm',
	data: () => ({
		overlay: false,
		valid: false,
		name: '',
		nameRules: [
			(v) => !!v || 'Name erforderlich',
			(v) => (v && v.length <= 50) || 'Bitte nicht länger als 50 Zeichen.',
		],
		email: '',
		emailRules: [
			(v) => !!v || 'Email erforderlich',
			(v) => (v && v.length <= 50) || 'Bitte nicht länger als 50 Zeichen.',
			(v) => /.+@.+/.test(v) || 'Bitte eine gültige Emailadresse.',
		],
		message: '',
		messageRules: [
			(v) => !!v || 'Nachricht erforderlich',
			(v) => (v && v.length > 0) || 'Bitte eine Nachricht eingeben.',
			(v) => (v && v.length < 1000) || 'Bitte maximal 1000 Zeichen.',
		],
	}),
	methods: {
		submit() {
			const form = document.createElement('form');
			const nameField = document.createElement('input');
			const emailField = document.createElement('input');
			const messageField = document.createElement('input');
			form.method = 'post';
			form.action = 'https://fewokruse.de/php/mail.php';
			form.target = 'hidden-form';

			nameField.type = 'hidden';
			nameField.name = 'name';
			nameField.value = this.name;
			form.appendChild(nameField);

			emailField.type = 'hidden';
			emailField.name = 'email';
			emailField.value = this.email;
			form.appendChild(emailField);

			messageField.type = 'hidden';
			messageField.name = 'message';
			messageField.value = this.message;
			form.appendChild(messageField);

			document.body.appendChild(form);

			form.submit();
			this.overlay = true;
			form.remove();
			this.$refs.contactform.reset();
		},
	},
};
</script>

<style></style>
