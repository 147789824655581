<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="8">
				<v-carousel height="100%" cycle show-arrows-on-hover>
					<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src"></v-carousel-item>
				</v-carousel>
			</v-col>
			<v-col>
				<h4>Gemeinsam Essen &amp; Entspannen im Wohnzimmer</h4>
				<ul>
					<li>Großer Esstisch</li>
					<li>Eine gemütliche Couch</li>
					<li>Schnelles Internet über WLAN im ganzen Haus</li>
					<li>Bildschirm mit Streaming-Anschluss (Online-Mediatheken vorhanden, AirPlay-fähig, HDMI-Kabel vor Ort)</li>
					<li>Die Couch kann mit wenigen Handgriffen in ein Bett für zwei Personen umgebaut werden.</li>
					<li>Die Terasse ist mit Tisch und Stühlen sowie einem Kohlegrill ausgestattet.</li>
				</ul>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'WohnzimmerEG',
	data: () => ({
		items: [
			{
				src: 'https://fewokruse.de/img/wohnen01.jpg',
			},
			{
				src: 'https://fewokruse.de/img/wohnen02.jpg',
			},
			{
				src: 'https://fewokruse.de/img/wohnen03.jpg',
			},
		],
	}),
};
</script>

<style></style>
