<template>
	<v-container>
		<v-row>
			<v-col>
				<h4 class="mb-4">Schilksee</h4>
				Schilksee muss man gesehen haben. Der
				<a href="https://goo.gl/maps/svSx3rkTn5kVyTdHA" target="_blank">Olympiahafen</a> ist emsiger Dreh- und
				Angelpunkt für die Sportsegler. Direkt daneben ist der Strand Schilksee und in Laufweite befindet sich das
				Hafenkiosk sowie der Strandkorbverleih. Öffentliche Toiletten sind ebenfalls vorhanden.<br /><br />
				Sofern man nicht mit dem Fahrrad kommt: Als Parkmöglichkeit empfiehlt sich der große, gebührenfreie
				<a target="_blank" href="https://goo.gl/maps/stGkSqSgKUsNbve18">Parkplatz Tempest</a>.<br /><br />
				<!--
				<v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6"
					><b>Strandkorb inklusive!</b><br />In der Strandkorb-Saison 2023 (ca. Mai bis September) ist ein Strandkorb am
					Strand Schilksee im Mietpreis inbegriffen! Der Schlüssel hängt am Schlüsselbrett.
				</v-card>
				-->
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'SchilkseeBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			{
				title: 'Strand Schilksee',
				subtitle: 'Gebührenfreier Sandstrand',
				img: 'https://fewokruse.de/img/schilkseestrand.jpg',
				url: 'https://www.kiel.de/de/kultur_freizeit/baeder_straende/strand_schilksee.php',
				maps: 'https://goo.gl/maps/yvjtHbNohSM9cn9m6',
				descr:
					'Ruhiger Strand, hinter Wellenbrechern geschützt. Kinderfreundlich, da sehr flacher Einstieg. Strandkorbverleih vor Ort im "Panorama 26".',
			},
			{
				title: 'Panorama 26',
				subtitle: 'Restaurant & Strandkorbvermietung',
				img: 'https://fewokruse.de/img/panorama26.jpg',
				url: 'https://www.panorama26.de/',
				maps: 'https://goo.gl/maps/g6BcLR1vrn2aLnsF8',
				descr:
					'Einfache Gerichte und kühle wie heiße Getränke für zwischendurch - auch zum Mitnehmen. Wer einen Strandkorb mieten will, ist hier richtig.',
			},
			{
				title: 'Hafenkiosk Goldfisch',
				subtitle: 'Fischbrötchen - frisch & lecker',
				img: 'https://fewokruse.de/img/goldfisch.jpg',
				url: 'https://www.goldfisch-kiel.de/',
				maps: 'https://goo.gl/maps/dVbn3CMZasfxNv7aA',
				descr:
					'Wer Fischbrötchen mag, kommt an diesem Kiosk nicht vorbei. Und wer sie nicht mag, könnte hier umgestimmt werden.',
			},
			{
				title: 'El Mövenschiss',
				subtitle: 'Gemütliches Restaurant mit Hafenblick',
				img: 'https://fewokruse.de/img/elmoevenschiss.jpg',
				url: 'https://elmoevenschiss-schilksee.de/',
				maps: 'https://g.page/moevenschiss?share',
				descr: 'Leckere Gerichte aus Fisch oder Fleisch, tolle Salate - hier wird jeder satt. Unbedingt reservieren!',
			},
			{
				title: 'Eiswerk Schilksee',
				subtitle: 'Eisdiele mit Ausblick',
				img: 'https://fewokruse.de/img/eiswerk.jpg',
				url: 'https://eiswerk-schilksee.de/',
				maps: 'https://maps.app.goo.gl/gxzu6x1VKWEREeCLA',
				descr:
					'Tolles Eis mit Zutaten von regionalen Erzeugern. Köstlicher Moccachino. Definitiv einen Besuch wert! Öffnungszeiten außerhalb der Saison beachten.',
			},
			{
				title: 'REWE Schilksee',
				subtitle: 'An der Fördestraße günstig gelegen',
				img: 'https://fewokruse.de/img/reweschilksee.jpg',
				url: 'https://www.rewe.de/marktseite/kiel/561220/rewe-markt-langenfelde-126a?ecid=seo_localseo_561220_g_nn_nn_nn_nn_nn',
				maps: 'https://goo.gl/maps/rSFUELs9yFkXQ1dT9',
				descr:
					'Auf dem Weg zum Strand noch schnell was besorgen? Durch die günstige Lage nur ein kleiner Abstecher. Hier kann man auch Sonntags Brötchen holen.',
			},
		],
	}),
};
</script>

<style></style>
