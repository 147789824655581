<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="8">
				<v-carousel height="100%" cycle show-arrows-on-hover>
					<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src"></v-carousel-item>
				</v-carousel>
			</v-col>
			<v-col>
				<h4>Die Küche ist für (fast) alles gerüstet:</h4>
				<ul>
					<li>Induktionskochfeld</li>
					<li>Backofen</li>
					<li>Geschirrspülmaschine</li>
					<li>Kühlschrank mit Gefrierfach</li>
					<li>Mikrowelle (mit Grillfunktion)</li>
					<li>Filterkaffeemaschine</li>
					<li>Kochgeschirr &amp; Pfannen</li>
				</ul>
				<br />
				<h4>Im Keller steht bereit:</h4>
				<ul>
					<li>Waschmaschine (inkl. Trockner)</li>
					<li>Wäscheständer bzw. Wäscheleine</li>
				</ul>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'KuecheEG',
	data: () => ({
		items: [
			{
				src: 'https://fewokruse.de/img/kueche001.jpg',
			},
			{
				src: 'https://fewokruse.de/img/kueche002.jpg',
			},
			{
				src: 'https://fewokruse.de/img/kueche003.jpg',
			},
		],
	}),
};
</script>

<style></style>
