<template>
	<v-container>
		<v-row>
			<v-col>
				<h4 class="mb-4">Friedrichsort</h4>
				Der kleine Kieler Stadtteil Friedrichsort bietet alle Einkaufsmöglichkeiten in direkter Nähe. Neben den großen
				Ketten <a href="https://goo.gl/maps/1Td4Kpcpx2zuRAQ7A" target="_blank">Aldi</a>,
				<a href="https://goo.gl/maps/7HB3DTBqrVwUbyGQ7" target="_blank">Lidl</a> und
				<a href="https://goo.gl/maps/VqpSJ7vR14FR1tXU7" target="_blank">Rewe</a> findet man auch allerlei kleine
				Fachgeschäfte (s.u.).<br /><br />
				Parkmöglichkeiten bestehen - neben den Supermarktparkplätzen - z.B. auf dem
				<a href="https://goo.gl/maps/Z6VPPqnQvbU5m1fL6" target="_blank">Heinrich Rixen Platz</a> oder dem
				<a href="https://goo.gl/maps/9Mto39R2eVdSE5EK6" target="_blank">Parkplatz Brauner Berg</a>.
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'FriedrichsortBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			{
				title: 'Bäckerei Rönnau',
				subtitle: 'Bäckerei mit Café',
				img: 'https://fewokruse.de/img/roennau.jpg',
				url: 'https://www.baeckerei-roennau.de/kiel-friedrichsort/',
				maps: 'https://goo.gl/maps/EPd9opgA9okuK6y98',
				descr: 'Brötchen, Brote und Kuchen - alles vorhanden. Auch Sonntags!',
			},
			{
				title: 'Die SprottenFlotte',
				subtitle: 'Das Bikesharing-System in der KielRegion',
				img: 'https://fewokruse.de/img/sprottenflotte.jpg',
				url: 'https://www.kielregion.de/sprottenflotte/',
				maps: '',
				descr: 'Radverleih schon ab 9 EUR pro Tag',
			},
			{
				title: 'Bäckerei Steiskal',
				subtitle: 'Bäckerei mit Café',
				img: 'https://fewokruse.de/img/steiskal.jpg',
				url: 'https://www.baecker-steiskal.de',
				maps: 'https://goo.gl/maps/hFX8McsFtnHzRGwh6',
				descr: 'Auch hier ist alles vorhanden, was man sich von einer Bäckerei wünschen kann. Auch Sonntags!',
			},
			{
				title: 'Metzgerei Hasch',
				subtitle: 'Fleischerei & Partyservice',
				img: 'https://fewokruse.de/img/hasch.jpg',
				url: 'https://fleischereihasch.de',
				maps: 'https://goo.gl/maps/5jiTeu5AVYonFnE79',
				descr: 'Wurst und Fleisch frisch vom Metzger.',
			},
			{
				title: 'Eis von Neitsch',
				subtitle: 'Inhabergeführte Eisdiele',
				img: 'https://fewokruse.de/img/neitsch.jpg',
				url: 'https://www.eis-neitsch.de/content/index.html',
				maps: 'https://goo.gl/maps/v9vQMLD2aSMtykg99',
				descr: 'Ob im Becher oder in der Waffel - unbedingt dort mal ein Eis probieren!',
			},
			{
				title: 'Flippers Fischladen',
				subtitle: 'Inhabergeführter Fischladen',
				img: 'https://fewokruse.de/img/flippers.jpg',
				url: 'https://sommer-friedrichsort.de/angebote/flipper',
				maps: 'https://goo.gl/maps/MrWredkfe4FDyJMt6',
				descr: 'Für Fans von leckerem Fisch ein Muss! Abwechslungsreicher Mittagstisch und zum Mitnehmen.',
			},
		],
	}),
};
</script>

<style></style>
