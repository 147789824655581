<template>
	<div>
		<v-parallax height="400" src="https://fewokruse.de/img/background1.jpg">
			<v-row align="center" justify="center">
				<v-col class="text-center" cols="12">
					<h2 class="subheading font-weight-thin">Die Ostsee in der Kieler Bucht.</h2>
				</v-col>
			</v-row>
		</v-parallax>
		<v-container>
			<v-row class="text-center">
				<v-col class="mb-4">
					<h2 class="mb-3">Moin und herzlich willkommen!</h2>

					<p class="subheading font-weight-regular">
						Wir vermieten von privat unsere Ferienwohnung in
						<a href="https://goo.gl/maps/WGZziykZLAsMKnft8" target="_blank">Kiel, Pries - Friedrichsort</a>.<br />Auf
						dieser Website findest du alle relevanten Infos und Kontaktmöglichkeiten.
					</p>
					<!--
					<v-card :elevation="hover ? 24 : 6" class="mx-auto pa-6"
						><b>Strandkorb inklusive!</b><br />In der Strandkorb-Saison 2023 (ca. Mai bis September) ist ein Strandkorb
						am Strand Schilksee im Mietpreis inbegriffen! Der Schlüssel hängt am Schlüsselbrett.
					</v-card>
					-->
				</v-col>
			</v-row>
		</v-container>
		<v-parallax height="400" src="https://fewokruse.de/img/background2.jpg">
			<v-row align="center" justify="center">
				<v-col class="text-center" cols="12">
					<h2 class="subheading font-weight-thin">Einfach entspannen in Schilksee am Strand.</h2>
				</v-col>
			</v-row>
		</v-parallax>
		<v-container>
			<v-row class="text-center">
				<v-col class="mb-4">
					<h2 class="mb-3">Unkompliziert &amp; einfach</h2>
					<p class="subheading font-weight-regular">
						Über das Menü ("<v-icon>mdi-menu</v-icon>" oben links klicken, um es aufzuklappen) kannst du dich umfassend
						über die Wohnung und die Umgebung informieren.<br />
						Außerdem hast du die Möglichkeit, die Verfügbarkeit zu prüfen und schließlich Kontakt aufzunehmen.
					</p>
				</v-col>
			</v-row>
		</v-container>
		<v-parallax height="400" src="https://fewokruse.de/img/background3.jpg">
			<v-row align="center" justify="center">
				<v-col class="text-center" cols="12">
					<h2 class="subheading font-weight-thin">Den Blick schweifen lassen in Bülk.</h2>
				</v-col>
			</v-row>
		</v-parallax>
	</div>
</template>

<script>
export default {
	name: 'HomeComponent',

	data: () => ({}),
};
</script>
