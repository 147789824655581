<template>
	<v-container>
		<h4 class="mb-4">Strande</h4>
		<v-row>
			<v-col cols="12" md="6" xl="4" v-for="l in locations" :key="l.title">
				<LocationCard :l="l" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocationCard from './LocationCard.vue';

export default {
	name: 'StrandeBase',
	components: {
		LocationCard,
	},
	data: () => ({
		locations: [
			/*
			{
				title: 'Bistro Strande',
				subtitle: 'Bistro, Eisdiele, Strandbar',
				img: 'https://fewokruse.de/img/strandbistro.jpg',
				url: 'https://bistrostrande.de/',
				maps: 'https://goo.gl/maps/qksHjxBWC1KbucH49',
				descr:
					'Ein kleiner oder auch ein großer Snack sowie Getränke und Eis - hier findet eigentlich jeder was für das leibliche Wohl.',
			},
			*/
			{
				title: 'Das Kaiser',
				subtitle: 'Café und Bistro',
				img: 'https://fewokruse.de/img/kaiser.jpg',
				url: 'http://www.daskaiser.de/de/',
				maps: 'https://goo.gl/maps/vjyPhd2BFgGkm9hH7',
				descr:
					'Ein waschechter Tiroler als Wirt an der Ostsee bietet hier eine sehr schmackhafte Küche! Die Terasse am Strand ist heiß begehrt. Unbedingt mal ausprobieren.',
			},
			{
				title: 'Strandhotel',
				subtitle: 'Elegantes Restaurant direkt an der Strandpromendade',
				img: 'https://fewokruse.de/img/strandhotel.jpg',
				url: 'https://strandhotel.de',
				maps: 'https://goo.gl/maps/BR3qxRBXQ3oe62gk9',
				descr:
					'Ein schönes Restaurant mit leckeren Gerichten. Bei schönem Wetter lockt der gepflegte Garten zur Strandpromenade hin zum Verweilen.',
			},
		],
	}),
};
</script>

<style></style>
